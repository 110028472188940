import React from "react";
import "./../styles/Home.css";
import deliveryImage from "../assets/imgsite4.png";
import restaurantImage from "../assets/restaurant.jpg";
import appPreview from "../assets/smartphone.JPG";
import googlePlay from "../assets/google-apple.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent, faGift, faCog, faUsers, faChartLine, faEye, faHandshake, faPen } from '@fortawesome/free-solid-svg-icons';


function Home() {
  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>🍽️ Bonn'App</h1>
          <p>📱 Commandez vos repas dans vos restaurants favoris de manière simple et rapide.</p>
          <p>🎉 Profitez d'offres exclusives pour vos loisirs (imagix, bowling, paintball et bien plus) grâce à Bonn'App.</p>
          <p>🚀 Disponible prochainement sur Android et Apple. Restez connectés !</p>
          <div className="app-badges">
            <img src={googlePlay} alt="Bientôt sur Apple et Google Play" />
          </div>
        </div>
        <div className="hero-image">
          <img src={deliveryImage} alt="Livraison de repas" />
        </div>
      </section>

      {/* Fonctionnalités */}
      <section className="features">
        <h2>Notre fonctionnement en 3 étapes simples !</h2>
        <div className="feature-cards">
          <div className="feature-card">
            <img src={restaurantImage} alt="Trouver un restaurateur" />
            <h3>Trouvez un restaurateur</h3>
            <p>Recherchez facilement les restaurants proches de vous avec notre app intuitive.</p>
          </div>
          <div className="feature-card">
            <img src={appPreview} alt="Choisissez votre repas" />
            <h3>Choisissez votre repas</h3>
            <p>Explorez les menus et commandez ce que vous aimez.</p>
          </div>
          <div className="feature-card">
            <img src={deliveryImage} alt="Recevez ou récupérez" />
            <h3>Recevez ou récupérez</h3>
            <p>Faites-vous livrer ou récupérez votre commande directement sur place.</p>
          </div>
        </div>
      </section>

 {/* Pour les Restaurateurs */}
        <section className="for-restaurants">
        <h2>Un partenariat gagnant-gagnant pour votre succès !</h2>
        <div className="benefits">
            {/* Première ligne */}
            <div className="benefit">
            <FontAwesomeIcon icon={faPercent} size="3x" className="orange-icon" />
            <h3>0% de frais en 2025</h3>
            <p>Notre objectif est de soutenir les restaurateurs sans coûts initiaux en 2025.</p>
            </div>
            <div className="benefit">
            <FontAwesomeIcon icon={faGift} size="3x" className="orange-icon" />
            <h3>Récompenses exclusives</h3>
            <p>Les clients accumulent des avantages comme des cadeaux et des réductions chez Imagix.</p>
            </div>
            <div className="benefit">
            <FontAwesomeIcon icon={faCog} size="3x" className="orange-icon" />
            <h3>Système simple et efficace</h3>
            <p>Une plateforme intuitive pour les restaurateurs, les clients, et les administrateurs.</p>
            </div>
            <div className="benefit">
            <FontAwesomeIcon icon={faChartLine} size="3x" className="orange-icon" />
            <h3>Augmentez vos clients</h3>
            <p>Rejoignez une communauté d'utilisateurs fidèles qui adorent commander chez vous.</p>
            </div>
        </div>

        {/* Deuxième ligne */}
        <div className="benefits">
            <div className="benefit">
            <FontAwesomeIcon icon={faPercent} size="3x" className="orange-icon" />
            <h3>5% de frais à partir de 2026</h3>
            <p>Après 2025, les frais seront de seulement 5%, une solution avantageuse pour tous.</p>
            </div>
            <div className="benefit">
            <FontAwesomeIcon icon={faHandshake} size="3x" className="orange-icon" />
            <h3>Aucun engagement</h3>
            <p>Testez la plateforme sans engagement à long terme ni risques financiers.</p>
            </div>
            <div className="benefit">
            <FontAwesomeIcon icon={faUsers} size="3x" className="orange-icon" />
            <h3>Égalité entre restaurateurs</h3>
            <p>Nous mettons en avant tous les restaurateurs équitablement, quel que soit leur taille.</p>
            </div>
            <div className="benefit">
            <FontAwesomeIcon icon={faPen} size="3x" className="orange-icon" />
            <h3>Inscription gratuite</h3>
            <p>Rejoignez-nous sans frais d’inscription et commencez à attirer des clients dès le lancement de notre app.</p>
            </div>
        </div>
        </section>
    </div>
  );
}

export default Home;

